import { useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { useSearchParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

export default function useStripeCheckout() {
  const { user } = useAuthenticator();
  // @ts-ignore
  const token = user?.signInUserSession?.idToken?.jwtToken;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      const session_id = searchParams.get("session_id");
      const success = searchParams.get("success");

      if (session_id && success) {
        const requestData = {
          headers: {
            Authorization: token,
          },
        };
        await API.post(
          "hypLambdaPayment",
          "/checkout?checkoutSessionId=" + session_id,
          requestData
        );

        const cognitoUser = await Auth.currentAuthenticatedUser();

        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          (err: any, session: any) => {}
        );

        searchParams.delete("session_id");
        searchParams.delete("success");
        setSearchParams(searchParams);
      }
    })();
  }, [searchParams, setSearchParams, token]);
}
