import React from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <div  style={{ background: 'radial-gradient(75.31% 100% at 50% 0%, #292929 0%, #101010 100%)', margin:'-16px', marginTop:'16px' }}>
            <Container style={{paddingTop:'16px'}}>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <img src="./assets/images/px-Hybrid-Logo.webp" alt="Logo" height="54" />
                        <Typography variant="body1" className="my-4 mt-1" style={{ color: 'white' }}>
                            HybridPoker, Inc. Copyright © 2022. All Rights Reserved.
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={6} md={3}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    <Link href={process.env.REACT_APP_WEBSITE_URL} underline="none" variant="body1" className="my-1" style={{ color: 'white' }}>Home</Link>
                                    <Link href={process.env.REACT_APP_WEBSITE_URL+"/privacy-policy/index.html"} underline="none" variant="body1" className="my-1" style={{ color: 'white' }}>Privacy Policy</Link>
                                    <Link href={process.env.REACT_APP_WEBSITE_URL+"/terms-conditions/index.html"} underline="none" variant="body1" className="my-1" style={{ color: 'white' }}>Terms of Service</Link>
                                    <Link href={process.env.REACT_APP_WEBSITE_URL+"/support/index.html"} underline="none" variant="body1" className="my-1" style={{ color: 'white' }}>Support</Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-4">
                        <Grid container justifyContent="flex-start">
                            <Grid item>
                                <a aria-label="discord" href="https://discord.gg/3cg4VjcMya" target="_blank">
                                    <img alt="discord" src="./assets/images/join_discord.png" height="60px" />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;
