export function toTime(d:number) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + ":": "";
    var mDisplay = m > 0 ? m + ":": "";
    if(hDisplay && m<10){
        mDisplay = '0'+mDisplay
    }
    var sDisplay = s > 0 ? s : "";
    if (s<10){
        sDisplay = '0'+sDisplay
    }
    return hDisplay + mDisplay + sDisplay + (mDisplay ==="" ? ' sec':'');
}
