export function redirectToRootHostname() {
    // Get the current URL
    var currentURL = window.location.href;

    // Parse the URL to get the hostname, protocol, and port
    var urlObject = new URL(currentURL);
    var rootURL = urlObject.protocol + '//' + urlObject.hostname;

    // Add port if it exists in the current URL
    if (urlObject.port) {
        rootURL += ':' + urlObject.port;
    }

    // Redirect to the root hostname with the main path
    window.location.href = rootURL;
}
