import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";

export function ConfirmPassFooter() {
  const { toSignIn } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Link fontSize={14} onClick={toSignIn}>
        Back to Sign In
      </Link>
    </Flex>
  );
}
