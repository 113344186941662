import { Flex, Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
    const { tokens } = useTheme();

    return (
        <Flex justifyContent="center">
            <a href={process.env.REACT_APP_WEBSITE_URL || "https://www.hybridpoker.com"}>
                <Image
                    alt="logo"
                    src="./assets/images/Hybrid-Logo-Hor-2x.png"
                    padding={tokens.space.medium}
                />
            </a>
        </Flex>
    );
}
