import {
    Flex,
    useAuthenticator,
    Image,
    View,
    CheckboxField,
    SearchField, Button, Loader,
} from "@aws-amplify/ui-react";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    debounce,
    Grid,
    ImageList,
    ImageListItem, LinearProgress, linearProgressClasses,
    Typography, useMediaQuery
} from "@mui/material";
import {Avatar, IconButton} from "@mui/material";
import React, {useEffect, useState, useLayoutEffect, useMemo} from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindTrigger} from "material-ui-popup-state";
import ProfileMenu from "../home/components/profileMenu";
import {createSearchParams, useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";
import {FiChevronLeft, FiFilter, FiChevronDown} from "react-icons/fi";
import {toTime} from "../utils/toTime";
import useVimeoTags from "../api/fetchTag";
import useVimeoVideos, {VimeoVideoObject} from "../api/fetchVideo";
import BackgroundSelector from "./components/backgroundSelector";
import {capitalizeFirstLetter} from "../utils/capitalizeFirstLetter";
import VideoPlayerModal from "./components/videoPlayerModal";
import useVideoProgress from "../api/getVideoProgress";
import {redirectToRootHostname} from "../utils/redirectToRootHostname";
import Vimeo from "@u-wave/react-vimeo";
import {getVimeoIdFromUri} from "../utils/getVimeoIdFromUri";
import {fetchVimeoVideoById} from "../api/fetchVideoByID";
import {useMutation} from "react-query";

const tagsMap: Record<string, string> = {
    "series_2bp_pfr_ip_basics": "Series = 2bp PFR IP - Basics",
    "series_beginnercourse": "Series = Beginner Course",
    "series_winatlowstakes": "Series = Win @ Low Stakes",
    "shorts": "Series = Shorts",
    "series_2bp_caller_oop_basics":"Series = 2bp Caller OOP - Basics",
    "series_2bp_pfr_oop_basics" : "Series = 2bp PFR OOP - Basics",
    "series_2bp_caller_ip" : "Series = 2bp Caller IP - Basics",
    "series_exploitinghumans" : "Series = Exploiting Humans",
    "series_hand_breakdowns" : "Series = Hand Breakdowns",
}

export function Video() {

    const {user} = useAuthenticator();
    const [hasSubscription, setHasSubscription] = useState<boolean>();
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [checkedTags, setCheckedTags] = useState<any[]>([])
    const popupState = usePopupState({
        variant: "popover",
        popupId: "profile-menu",
    });
    const navigate = useNavigate();
    const {data: tagList} = useVimeoTags()
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
        isFetched
    } = useVimeoVideos({query: searchValue ?? undefined, filterTags: checkedTags});
    const videos = useMemo(() => data?.pages.flatMap(page => page.data.map((v) => ({
        ...v,
        id: getVimeoIdFromUri(v.uri ?? ''),
        ratio: (v?.width ?? ((v?.duration && v.duration < 90) ? 1080 : 1920)) / (v?.height ?? (v?.duration && v.duration < 90) ? 1920 : 1080)
    }))) ?? [], [data])
    // @ts-ignore
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const {data: videoProgress} = useVideoProgress(token);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const id = params.get("id");
    const filterParam = params.get("filter");
    const checkSubscription = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser({bypassCache: true});
            const now = new Date().getTime() / 1000;
            if (
                user?.attributes["custom:subscriptionValidity"]
            ) {
                if (user.attributes["custom:subscriptionValidity"] > now) {
                    setHasSubscription(true)

                } else {
                    redirectToRootHostname()
                }
            } else {
                // if JWT is not updated
                const info = await Auth.currentUserInfo();
                if (info?.attributes["custom:subscriptionValidity"] &&
                    info.attributes["custom:subscriptionValidity"] > now) {
                    setHasSubscription(true)
                } else {
                    redirectToRootHostname()
                }
            }
        } catch (e) {
            console.log(e);
            redirectToRootHostname()
        }
    };
    useEffect(() => {
        checkSubscription();
    }, []);

    const fetchVimeoVideoByIdMutation = useMutation((videoId:string) => fetchVimeoVideoById(videoId));

    const fetchVideo = async (id:string)=>{
    let _id = id
    if(id.includes('/')){
        _id = getVimeoIdFromUri(id)
    }
    const _data = await fetchVimeoVideoByIdMutation.mutateAsync(_id);
    if(_data) {
        const _video: VimeoVideoObject = {
            id: getVimeoIdFromUri(_data.uri ?? ''),
            ratio: (_data?.width ?? ((_data?.duration && _data.duration < 90) ? 1080 : 1920)) / (_data?.height ?? (_data?.duration && _data.duration < 90) ? 1920 : 1080),
            uri: _data.uri,
            name: _data.name,
            duration: _data.duration,
            height: _data.height,
            width: _data.width,
            description: _data.description,
            link: _data.link,
            tags: _data.tags,
            pictures: _data.pictures,
        }
        handleOpen(_video)
    }

}
    useLayoutEffect(() => {
        if (id !== null && videos.length) {
            const _videos = videos.find(v => v.uri == id)
            if (_videos) {
                handleOpen(_videos);
            } else {
                fetchVideo(id)
            }
            navigate({
                search: createSearchParams({view: 'video'}).toString(),
            }, {replace: true});
        }
    }, [videos?.length]);

    useLayoutEffect(() => {
        let _tags: string[] = []
        if (filterParam !== null && tagList?.length) {
            if (filterParam.includes(',')) {
                const filterTags = filterParam?.toLowerCase()?.split(',')
                _tags = tagList.filter(v => filterTags?.includes(v.toLowerCase()))
            } else {
                _tags = tagList.filter(v => v.toLowerCase() == filterParam.toLowerCase())
            }
            if (_tags?.length) {
                setCheckedTags(_tags);
            }
            navigate({
                search: createSearchParams({view: 'video'}).toString(),
            }, {replace: true});
        }
    }, [tagList?.length]);


    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    };
    const [selectedVideo, setSelectedVideo] = useState<VimeoVideoObject | undefined>();
    const handleOpen = (_video: VimeoVideoObject) => {
        setHoveredVideo(null);
        setSelectedVideo(_video);
    }
    const handleClose = () => setSelectedVideo(undefined);
    const isXsScreen = useMediaQuery('(max-width:600px)');


    const [hoveredVideo, setHoveredVideo] = useState<VimeoVideoObject | null>(null);

    const handleHover = (video: VimeoVideoObject) => {
        if (isXsScreen) {
            return
        }
        setHoveredVideo(video);
    };

    const handleHoverExit = () => {
        setHoveredVideo(null);
    };


    return (
        <Box flex={1} height={'100vh'} width={'100vw'}>
            <Box sx={{display: 'flex', justifyContent: {sm: 'center', xs: 'flex-start'}, marginBottom: '16px'}}
                 height="50px" placeholder={undefined}>
                <a href={process.env.REACT_APP_WEBSITE_URL || "https://www.hybridpoker.com"}>
                    <Image
                        width={"300px"}
                        alt="logo"
                        src="./assets/images/Hybrid-Logo-Hor-2x.png" placeholder={undefined}/>
                </a>
                <Flex alignItems={"center"} style={{position: 'absolute', right: '16px', top: '16px'}}>
                    <>
                        <IconButton
                            data-cy="button-profile"
                            size="small"
                            {...bindTrigger(popupState)}
                        >
                            <Avatar
                                sx={{width: 36, height: 36, backgroundColor: "primary.light"}}
                            >
                                {user?.attributes?.name.slice(0, 1)}
                            </Avatar>
                        </IconButton>
                        <ProfileMenu hasSubscription={!!hasSubscription} {...popupState} />
                    </>
                </Flex>
            </Box>

            <Flex direction="column" overflow={'hidden'}>
                <Grid container my={"16px"} px={'30px'} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Flex gap={1} direction="row" alignItems={'center'} height={'100%'}>
                            <IconButton sx={{background: '#000000ab'}}
                                        onClick={() => window.location.href = window.location.origin}>
                                <FiChevronLeft color={'white'}/>
                            </IconButton>
                            <Typography variant={"h5"}>Video Training</Typography>
                        </Flex>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{display: 'flex', justifyContent: {xs: 'center', sm: 'flex-end'}}}>
                            <Box sx={{background: 'white', borderRadius: "12px", overflow: "hidden"}}>
                                <SearchField
                                    label="Search"
                                    placeholder="Search here..."
                                    style={{background: "white"}}
                                    onChange={debounce((e) => {
                                        onChangeSearch(e)
                                    }, 1000)}
                                    onClear={() => setSearchValue("")}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>


                <Flex className="hyp-dual-view" justifyContent="space-between" paddingTop={'16px'}>
                    <View
                        style={{
                            backgroundColor: "transparent",
                            padding: "16px",
                            paddingLeft: "30px",
                            paddingTop: 0,
                            borderRadius: "10px"
                        }}>
                        <Box p={'18px'} width={'unset'} height={'unset'} sx={{background: '#000000ab'}}
                             className="card shadow-sm hyb-manage-card">
                            <Accordion defaultExpanded={!isXsScreen} sx={{background: 'transparent'}}>
                                <AccordionSummary
                                    expandIcon={<FiChevronDown size={'20px'} color={'white'}/>}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{margin: 0}}
                                >
                                    <Flex gap={'4px'} marginBottom={'8px'} direction={'row'}
                                          alignItems={'center'}><FiFilter
                                        color={'white'} size={'18px'}/> <Typography color={'white'} fontSize={'18px'}
                                                                                    textAlign={'left'}>Filters </Typography></Flex>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {tagList?.map((tag) => (
                                        <CheckboxField
                                            key={tag}
                                            id={tag}
                                            label={<Typography
                                                color={checkedTags.includes(tag) ? '#ffffff' : '#ffffffbf'}>{tagsMap[tag] ?? capitalizeFirstLetter(tag)}</Typography>}
                                            name={tag}
                                            value={tag}
                                            checked={checkedTags?.includes?.(tag)}
                                            paddingTop={'8px'}
                                            onChange={(event) => {
                                                const {checked} = event.target;
                                                if (checked) {
                                                    setCheckedTags((prevTagList) => [...prevTagList, tag]);
                                                } else {
                                                    setCheckedTags((prevTagList) => prevTagList.filter((prevTag) => prevTag !== tag));
                                                }
                                            }}
                                        />
                                    ))}
                                </AccordionDetails>
                            </Accordion>


                        </Box>
                    </View>

                    <View style={{
                        backgroundColor: "transparent",
                        flex: 1,
                        padding: '16px',
                        paddingTop: 0,
                        height: 'unset',
                        width: 'unset',
                        paddingRight: '30px'
                    }}>

                        {isFetching && !isFetchingNextPage ? <Loader
                                style={{
                                    position: "absolute",
                                    top: "25em",
                                    left: "50%",
                                    zIndex: 0,
                                    height: "60px",
                                }}
                                size="large"
                            /> :
                            isFetched && !videos.length ?
                                <Typography variant={'h6'}>We couldn’t find results.</Typography> :
                                <ImageList sx={{
                                    gridTemplateColumns:
                                        'repeat(auto-fill, minmax(356px, 1fr))!important',
                                    width: '100%', height: '100%',
                                    marginTop: 0
                                }}
                                           gap={20}>
                                    {videos.map((video, index) => (
                                        <Box key={video.uri + index}>
                                            <ImageListItem onClick={() => handleOpen(video)}
                                                           onMouseEnter={() => handleHover(video)}
                                                           onMouseLeave={handleHoverExit}
                                                           sx={{cursor: 'pointer'}}
                                            >
                                                <Box textAlign={'center'} width={'unset'} height={'unset'}
                                                     className="card shadow-sm hyb-manage-card"
                                                     sx={{background: '#000000ab', padding: '20px'}}>
                                                    <Box sx={{position: 'relative'}}>

                                                        {/*@ts-ignore*/}
                                                        <Image src={video.pictures?.sizes[3]?.link_with_play_button}
                                                               alt={video?.name} placeholder={undefined}
                                                               style={{borderRadius: '12px', overflow: 'hidden'}}
                                                               onClick={() => handleOpen(video)}></Image>
                                                        {hoveredVideo?.uri === video?.uri && (
                                                            <Box
                                                                position="absolute"
                                                                top="0"
                                                                left="0"
                                                                right="0"
                                                                bottom="0"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                bgcolor="black"
                                                                zIndex="1"
                                                                width='100%'
                                                                height='100%'
                                                                style={{borderRadius: '12px', overflow: 'hidden'}}
                                                            >
                                                                <Vimeo
                                                                    video={
                                                                        video.id
                                                                    }
                                                                    autoplay={true}
                                                                    responsive
                                                                    controls={false}
                                                                    muted={true}
                                                                    showTitle={false}
                                                                    style={{
                                                                        borderRadius: '12px',
                                                                        // width: '100%',
                                                                        // height: '100%',
                                                                        cursor: 'pointer',
                                                                        ...((video?.width ?? 0) > (video?.height ?? 0) ? {
                                                                            width: '100%',
                                                                        } : {
                                                                            height: '96%',
                                                                        }),
                                                                        aspectRatio: video.ratio + '',
                                                                        margin: 'auto',
                                                                        marginTop: 0
                                                                    }}
                                                                    playsInline={true}
                                                                    showByline
                                                                />
                                                                <Box
                                                                    position="absolute"
                                                                    top="0"
                                                                    left="0"
                                                                    right="0"
                                                                    bottom="0"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    bgcolor="transparent"
                                                                    zIndex="2"
                                                                    width='100%'
                                                                    height='100%'
                                                                    sx={{cursor: 'pointer'}}
                                                                    onClick={() => handleOpen(video)}
                                                                >
                                                                </Box>
                                                            </Box>
                                                        )}
                                                        {video.duration && <Box style={{
                                                            position: 'absolute',
                                                            bottom: '8px',
                                                            fontSize: '14px',
                                                            background: 'black',
                                                            margin: '8px',
                                                            letterSpacing: '1.5px',
                                                            padding: '4px',
                                                            paddingLeft: '8px',
                                                            paddingRight: '8px',
                                                            borderRadius: '4px'
                                                        }
                                                        }>{toTime(video.duration)}</Box>}
                                                        {videoProgress?.[video.id] && hoveredVideo?.uri !== video?.uri &&
                                                            <LinearProgress sx={{
                                                                position: 'absolute',
                                                                bottom: '7px',
                                                                width: '100%',
                                                                opacity: '0.6',
                                                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                                                    backgroundColor: 'rgb(99 99 99)',
                                                                },
                                                                [`& .${linearProgressClasses.bar}`]: {
                                                                    borderRadius: 5,
                                                                    backgroundColor: 'red',
                                                                },
                                                            }} variant="determinate"
                                                                            value={((videoProgress ? videoProgress[video.id] : 0) ?? 0) * 100}/>}
                                                    </Box>
                                                    <Typography variant={"body1"}
                                                                textAlign={'left'}>{video.name}</Typography>
                                                    {!!video.description &&
                                                        <Typography mt={1} variant={"body2"}
                                                                    textAlign={'left'}>
                                                            {video.description}
                                                        </Typography>}

                                                </Box>
                                            </ImageListItem>
                                        </Box>

                                    ))}
                                </ImageList>}
                    </View>
                </Flex>
                {hasNextPage &&
                    <Button disabled={isFetching || isFetchingNextPage} isLoading={isFetching || isFetchingNextPage}
                            style={{width: '150px', margin: 'auto', color: 'white', marginBottom: '16px'}}
                            onClick={() => fetchNextPage()}>Load
                        more</Button>}
            </Flex>

            <BackgroundSelector/>
            <VideoPlayerModal token={token}
                              initialProgress={+((selectedVideo?.id ? videoProgress?.[selectedVideo.id] : 0) ?? 0)}
                              selectedVideo={selectedVideo} onClose={handleClose}/>
        </Box>
    );
}
