import {
  Flex,
  useAuthenticator,
  Card,
  Image,
  Loader,
  Text,
  Button,
  View
} from "@aws-amplify/ui-react";
import {useEffect, useMemo, useState} from "react";
import { API, Auth } from "aws-amplify";
import useStripeCheckout from "./hooks/useStripeCheckout";
import BuyPlan from "./components/buyPlan";
import { Avatar, Box, IconButton, Typography, Modal } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import ProfileMenu from "./components/profileMenu";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader"
import { Carousel } from 'react-responsive-carousel';
import {useNavigate} from "react-router-dom";
import DicordCard from "./components/discordCard";
import useVimeoVideos from "../api/fetchVideo";
import {FiX} from "react-icons/fi";
import * as React from "react";
import Footer from "./components/footer";
import Testimonials from "./components/testimonials";

export function Home() {
  const { user , authStatus} = useAuthenticator();
  const [hasSubscription, setHasSubscription] = useState<boolean>();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "profile-menu",
  });
  const navigate = useNavigate()

  let search = window.location.search;
  let params = new URLSearchParams(search);

  useEffect(() => {
    if(authStatus ==='authenticated'){
      const redirectKey = window.sessionStorage.getItem("redirectTo")
      if(redirectKey && redirectKey!='undefined' && redirectKey!='/undefined'){
        navigate(redirectKey)
        window.sessionStorage.removeItem("redirectTo");
      }
      const queryParamsString = sessionStorage.getItem('queryParams');
      if (queryParamsString !== null) {
        let params = new URLSearchParams(queryParamsString);
        const redirectToMVP = params.get("redirectToMVP");
        if(redirectToMVP){
          sessionStorage.removeItem('queryParams');
          window.location.href = process.env.REACT_APP_MVP_URL + '?' + queryParamsString;
          return
        }
        const currentUrl = window.location.href;
        const updatedUrl = currentUrl + (currentUrl.includes('?') ? '&' : '?') + queryParamsString;
        window.history.replaceState({ path: updatedUrl }, '', updatedUrl);
        sessionStorage.removeItem('queryParams');
      }else{
        const redirectToMVP = params.get("redirectToMVP");
        if(redirectToMVP){
          window.location.href = process.env.REACT_APP_MVP_URL + search
          return
        }
      }
    }
  }, [authStatus]);

  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState(params.get("flow"));
  // @ts-ignore
  const token = user?.signInUserSession?.idToken?.jwtToken;
  const [isPreflopUser, setIsPreflopUser] = useState(false);
  const preflopPlan = JSON.parse(process.env.REACT_APP_PRE_FLOP_PLAN ?? "{}");

  useEffect(() => {
    if (user?.attributes?.email) {
      // @ts-ignore
      window?.fpr?.("referral", { email: user?.attributes?.email });
    }
  }, [user?.attributes?.email]);

  const manage = async (isPreflopUser?: boolean) => {
    const requestData = {
      headers: {
        Authorization: token,
      },
    };
    popupState.close();
    const data = await API.put(
      "hypLambdaPayment",
      `/checkout?upgrade=${Boolean(isPreflopUser)}`,
      requestData
    );
    if (data.url) window.location.href = data.url;
  };
  useEffect(() => {
    if (!!hasSubscription && flow === "manage") {
      setLoading(true);

      manage();
      setFlow(null);
    }
  }, [hasSubscription]);

  useStripeCheckout();

  const checkSubscription = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const now = new Date().getTime() / 1000;

      if (
        user?.attributes["custom:subscriptionValidity"] &&
        user.attributes["custom:subscriptionValidity"] > now
      ) {
        if (
          user.attributes["custom:stripePriceId"] ===
          preflopPlan.monthly.stripeId
        ) {
          setIsPreflopUser(true);
        }
        setHasSubscription(true);
      } else {
        // if JWT is not updated
        const info = await Auth.currentUserInfo();
        if (
          info.attributes["custom:stripePriceId"] ===
          preflopPlan?.monthly?.stripeId
        ) {
          setIsPreflopUser(true);
        }
        setHasSubscription(
          Boolean(
            info?.attributes["custom:subscriptionValidity"] &&
              info.attributes["custom:subscriptionValidity"] > now
          )
        );
      }
    } catch (e) {
      console.log(e);
      setHasSubscription(false);
    }
  };
  useEffect(() => {
    checkSubscription();
  }, []);

    const {
        data,
        isFetching,
    } = useVimeoVideos({query:  undefined, filterTags: [],excludedTags:['shorts'], perPage:8});

  const videos = useMemo(() => data?.pages.flatMap(page => page.data.map((v) => ({
    ...v,
  }))) ?? [], [data])
  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

  return (
    <Card
      display={"flex"}
      height="100vh"
      columnStart="1"
      columnEnd="-1"
      style={{
        zIndex: 2,
        flexDirection: "column",
        overflowX: "hidden",
        ...(hasSubscription === false ? {background:'radial-gradient(circle at left bottom,#160d2a 0,#0f0e2e 43%,#0f0f0f 100%)'}:{background: "transparent"})
      }}
    >
      <Flex justifyContent="space-between" height="50px" placeholder={undefined}>
        <a
          href={process.env.REACT_APP_WEBSITE_URL || "https://www.hybridpoker.com"}
        >
          <Image
            width={"200px"}
            alt="logo"
            src="./assets/images/Hybrid-Logo-Hor-2x.png" placeholder={undefined}          />
        </a>
        <Flex alignItems={"center"} placeholder={undefined}>
          <>
            <IconButton
              data-cy="button-profile"
              size="small"
              {...bindTrigger(popupState)}
            >
              <Avatar
                sx={{ width: 36, height: 36, backgroundColor: "primary.light" }}
              >
                {user?.attributes?.name.slice(0, 1)}
              </Avatar>
            </IconButton>
            <ProfileMenu hasSubscription={!!hasSubscription} {...popupState} />
          </>
        </Flex>
      </Flex>

      <style></style>
      <Flex
        justifyContent="center"
        alignItems="center"
        grow={1}
        direction={"column"}
        style={{  background:hasSubscription === false ?'url(https://www.hybridpoker.com/dots-bg.8be02bab.png) repeat':'transparent'}}
      >
        <Flex placeholder={undefined}>
          {hasSubscription === undefined || loading ? (
            <Loader
              style={{
                position: "absolute",
                top: "25em",
                left: "50%",
                zIndex: 0,
                height: "60px",
              }}
              size="large"
            />
          ) : hasSubscription ? (
            <>
              <Flex
                  justifyContent="center"
                  alignItems="center"
                  grow={1}
                  marginTop={"2rem"}
                  direction={"column"}
                  placeholder={undefined}              >
                <Typography
                  m={0}
                  variant={"h4"}
                  className="hyb-sub-heading text-light"
                >
                  Hello!{" "}
                  <span className="text-gradient">
                    {user?.attributes?.name}
                  </span>
                </Typography>

                <Flex
                  justifyContent="space-between"
                  alignItems="flex-start" // Align content to the top
                    grow={1}
                    marginTop={"2rem"}
                    //direction={"row"} // Change direction to column on mobile
                    className="hyp-dual-view"
                  >
                  <View width="100%" className="column">
                    <Box
                      className="card shadow-sm hyb-manage-card"
                      p={2}
                      mt={5}
                      sx={{ height: "430px" }} // Adjust the height here
                    >
                      <div
                        className="card-header py-3"
                        style={{
                          borderBottom: "0.5px solid white",
                        }}
                      >
                        <Text marginBottom={"16px"} marginTop={"16px"} color={"white"} placeholder={undefined}>
                          Hybrid Poker App
                        </Text>
                      </div>
                      <Box mt={5} className="card-body">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight:'196px',
                              borderRadius:'12px', overflow:'hidden'
                            }}
                        >
                          <video style={{borderRadius:'12px', overflow:'hidden'}} autoPlay loop muted className="w-full h-full object-cover">
                            <source src={require('./video/new_gto_tv_video.mp4')} height="201px" type="video/mp4"/>
                            <Image src="./assets/images/tableAnimate.gif" width="358px" height="201px"
                                   alt="Table Animation" borderRadius={"12px"} overflow={"hidden"}
                                   placeholder={undefined}/>
                          </video>
                        </div>

                        <Button
                            onClick={() => (window.location.href = isPreflopUser
                                ? "https://preflop.hybridpoker.com"
                                : "https://mvp.hybridpoker.com")}
                            variation={"link"}
                            className="hyb-price-cta" placeholder={undefined}                        >
                          {isPreflopUser ? "GO TO APP" : "GO TO APP"}
                        </Button>
                        {/*<Button
                          onClick={() => manage(isPreflopUser)}
                          variation={"link"}
                          className="hyb-price-cta"
                        >
                          {isPreflopUser ? "Unlock Full Access" : "Manage Subscription"}
                        </Button>
                          */}

                      </Box>
                    </Box>
                    <DicordCard/>
                  </View>
                  <View width="100%">
                  <Box
                  className="
                  card
                  shadow-sm
                  hyb-manage-card
                "
                  p={2}
                  mt={5}
                  sx={{ height: "650px" }} // Adjust the height here
                >
                  <div
                    className="card-header py-3"
                    style={{
                      borderBottom: "0.5px solid white",
                    }}
                  >
                    <Text
                            marginBottom={"16px"}
                            marginTop={"16px"}
                            color={"white"} placeholder={undefined}                    >
                      Video Training Content - Recent Videos
                    </Text>
                  </div>
                  <Box mt={5} className="card-body">

                    {videos?.length > 0 && (
                      <>
                        {/*@ts-ignore*/}
                        <Image  src={videos[0]?.pictures?.sizes[3]?.link_with_play_button}
                                alt={videos[0]?.name} placeholder={undefined}
                                onClick={handleOpen}  borderRadius={"12px"} overflow={"hidden"}                />

                        <Modal
                                open={open}
                                onClose={handleClose}
                              >
                          <>
                          <IconButton
                              sx={{background: '#000000ab', position: 'absolute', right: '8px', top: '8px', zIndex:2}}
                              onClick={handleClose}>
                            <FiX color={'white'} fontSize={'28px'}/>
                          </IconButton>
                                <iframe
                                  src={("https://player.vimeo.com" + videos[0]?.uri + "?badge=0&autopause=0&player_id=0&app_id=286892").replace("videos","video")}
                                  width="100%"
                                  height="500"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowFullScreen
                                  title={videos[0]?.name}
                                  style={style}
                                  />
                          </>
                          </Modal>
                      </>
                    )}

                      {!isFetching && videos?.length && <Carousel
                          autoPlay
                          stopOnHover
                          showThumbs={false}
                          infiniteLoop={true}
                          interval={2500}
                          onClickItem={() => (window.location.href = "/?view=video")}>
                          {videos.slice(1).map((video) => (
                              <div key={video.id}>
                                  {/*@ts-ignore*/}
                                  <Image src={video.pictures.sizes[3].link_with_play_button}
                                         alt={video.name}
                                         placeholder={undefined}
                                         borderRadius={"12px"} overflow={"hidden"}
                                         onClick={() => (window.location.href = "/?view=video")}
                                  />
                              </div>
                          ))}
                      </Carousel>}
                    <Button
                            onClick={() => (window.location.href = "/?view=video")}
                            variation={"link"}
                            className="hyb-price-cta" placeholder={undefined}                    >
                     Watch More Content
                    </Button>

                  </Box>
                  </Box>
                  </View>
                </Flex>
              </Flex>
            </>
          ) : (<Flex direction={'column'}>
            <BuyPlan
              flow={
                flow === "buy-yearly" || flow === "buy-monthly" ? flow : null
              }
              resetFlow={() => setFlow(null)}
            />
          <Testimonials/>
              </Flex>
          )}
        </Flex>
      </Flex>

      {hasSubscription === false && <Footer/>}
    </Card>
  );
}
