import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Authenticator } from "@aws-amplify/ui-react";
// @ts-ignore
import { SnackbarProvider } from "notistack";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <SnackbarProvider
      variant="info"
      transitionDuration={{ enter: 150, appear: 150, exit: 150 }}
      autoHideDuration={2000}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      maxSnack={1}
      preventDuplicate
    >
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>
    </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
