import {useMutation} from "react-query";

export const fetchVimeoVideoById = async (videoId:string) => {
    const response = await fetch(`https://api.vimeo.com/me/videos/${videoId}`, {
        headers: {
            Authorization: 'Bearer '+process.env.REACT_APP_VIMEO_TOKEN,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Vimeo video');
    }

    return response.json();
};

