import { Loader, ThemeProvider, useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify, API, Auth, Hub } from "aws-amplify";

import { Home } from "./home/home";
import { Login } from "./auth/login";
import { Video } from "./video/video";
import "./App.css";

import awsConfig from "./aws-exports";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React, { useEffect, useMemo, useRef } from "react";
import { theme } from "./theme/theme";
import { QueryClient, QueryClientProvider } from "react-query";
// @ts-ignore
import { useSnackbar } from "notistack";
import { debounce } from "@mui/material";
import Discord from "./discord/discord";

// Amplify.configure(awsConfig);

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  cookieStorage: {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    secure: false,
    path: "/",
    sameSite: "lax",
    expires: 365,
  },
  oauth: {
    ...awsConfig.oauth,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: false,
      sameSite: "lax",
      path: "/",
      expires: 365,
    },
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);
Auth.configure(updatedAwsConfig);

const App = () => {
  const { user , authStatus} = useAuthenticator();
  useEffect(() => {
    try{
      if(authStatus ==='unauthenticated' ){
        if(window.location.pathname !== '/') {
          window.sessionStorage.setItem("redirectTo", window.location.pathname);
        }
        const currentUrl = window.location.href;
        const queryParamsString = currentUrl.split('?')?.[1] || '';
        if(queryParamsString && !queryParamsString.includes('code=')) {
          sessionStorage.setItem('queryParams', queryParamsString);
        }
      }
    }catch (e){
      console.log(e)
    }
  }, [authStatus]);

  const { enqueueSnackbar } = useSnackbar();
  const checkForgotPassSSOType = useMemo(
    () =>
      debounce(async (username: string) => {
        const data = await API.get(
          "hypLambdaPayment",
          "/check?username=" + username,
          {}
        );
        if (data.message) {
          enqueueSnackbar(data.message, {
            variant: "warning",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          });
        }
      }, 500),
    []
  );

  useEffect(() => {
    Hub.listen("auth", (data) => {
      if (data.payload.event === "forgotPassword") {
        checkForgotPassSSOType(data.payload?.data?.username);
      }
    });
  }, []);
  let search = window.location.search;
  let params = new URLSearchParams(search);

  useEffect(() => {
    if (params) {
      const flow = params.get("flow");
      if (flow === "preflop") {
        sessionStorage.setItem("isPreflop", "true");
      }
    }
  }, []);

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        mutations: {
          onError: (error: any) => {
            enqueueSnackbar(error.message ?? "Request failed !", {
              variant: "error",
            });
          },
        },
        queries: {
          refetchOnMount: true,
          retry: false,
          staleTime: 15 * 1000,
          refetchOnWindowFocus: false,
        },
      },
    })
  );
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient.current}>
        <ThemeProvider theme={theme} colorMode={"light"}>
          <header className="App-header" id={"App-BG"}  style={{ backgroundImage: "url(/assets/images/bg-1.jpg)" }} >
            {user ? (
               params.get("view") === "video" ? (
                <Video />
              ) : (
                <Routes>
                  <Route index element={<Home />}/>
                  <Route path="/discord" element={<Discord />}/>
                  <Route path="*" element={<Home />}/>
                </Routes>
              )
            ) : (
              <>
                <Loader
                  style={{
                    position: "absolute",
                    top: "25em",
                    left: "50%",
                    zIndex: 0,
                    height: "60px",
                  }}
                  size="large"
                />
                <Login />
              </>
            )}
          </header>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
