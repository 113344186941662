import {Authenticator, Flex, useAuthenticator} from "@aws-amplify/ui-react";
import { Header } from "./header";
import {useEffect, useLayoutEffect} from "react";
import { useSnackbar } from "notistack";
import { ConfirmPassFooter } from "./ConfirmPassFooter";

const components = {
  Header: Header,
  ConfirmResetPassword: {
    Footer: ConfirmPassFooter,
  },
};

export function Login() {
  const { enqueueSnackbar } = useSnackbar();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let flow = params.get("flow");
  const {   authStatus} = useAuthenticator();

  useLayoutEffect(()=>{
    if(authStatus ==='unauthenticated') {
      const signupTabElement = document.querySelector("#radix-\\:r0\\:-trigger-1")
      if(signupTabElement){
        signupTabElement.addEventListener('click', ()=>{
          setTimeout(()=>{
            const appleBtn = document.querySelector('form[data-amplify-authenticator-signup]  button:nth-child(4)');
            if (appleBtn) {
              appleBtn.addEventListener('click', ()=>{
                alert("Please do not use the Private Relay (Hide My Email) option as it is buggy.");
              });
            } else {
              console.error('Button element not found');
            }
          },100)
        });
      }
      if(flow === "signup" ){
        setTimeout(()=>{
          const appleBtn = document.querySelector('form[data-amplify-authenticator-signup]  button:nth-child(4)');
          if (appleBtn) {
            appleBtn.addEventListener('click', ()=>{
              alert("Please do not use the Private Relay (Hide My Email) option as it is buggy.");
            });
          } else {
            console.error('Button element not found');
          }
        },100)
      }
    }
  },[authStatus, flow])

  useEffect(() => {
    (async () => {
      const error_description = window.localStorage.getItem("error");
      if (error_description) {
        if (error_description.includes("Please")) {
          const [_, message] = error_description.split("with error ");
          enqueueSnackbar(message ?? "Something went wrong.", {
            variant: "warning",
            autoHideDuration: 8000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          });
        }
        if (
          /already.found.an.entry.for.username.google/gi.test(
            error_description.toString()
          )
        ) {
        }
        window.localStorage.removeItem("error");
      }
    })();
  }, []);

  return (
    <Flex
      justifyContent="center"
      alignItems={"flex-start"}
      height="100%"
      style={{ zIndex: 1, paddingTop: "2em", paddingBottom: "2em" }}
    >
      <Authenticator
        initialState={flow === "signup" ? "signUp" : "signIn"}
        socialProviders={["google", "facebook", "amazon", "apple"]}
        loginMechanisms={["email"]}
        signUpAttributes={["name"]}
        formFields={{
          signUp: {
            name: {
              isRequired: true,
              order: 1,
            },
            email: {
              isRequired: true,
              order: 2,
              placeholder: "Email",
            },
            password: {
              isRequired: true,
              order: 3,
            },
          },
        }}
        components={components}
      />
    </Flex>
  );
}
