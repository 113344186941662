import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

const Testimonials = () => {
    return (
        <Grid container className="my-sm-32">
            <Grid item md={6} xs={12} px={1}   justifyContent={'center'} >
                <div style={{
                    backgroundImage: `url('./assets/images/img_Daniel_Negreanu.png')`,
                    backgroundPosition: 'center',
                    position:'relative',
                    backgroundSize: 'cover',
                    borderRadius: 16,
                    textAlign:'center',
                    padding:0,
                    width: '100%',
                    height: '600px',
                }}>
                    <div className="hyb-glass-card hyb-glass-card-bottom">
                        <CardContent>
                            <Typography variant="body1"  textAlign={'left'} className="text-light hyb-light-text text-left">
                                Hybrid Poker is the best combination of learning to study GTO and how to exploit your opponents. The process is very professional and effective. I've played half my life but have learned to think about the game at an even more advanced level as a result of my training with Hybrid Poker.
                            </Typography>
                        </CardContent>
                        <div style={{ borderTop: '0.75px solid white' }}>
                            <Grid container justifyContent="start" direction={'column'} alignItems="start" p={2}>
                                <Grid item>
                                    <Typography variant="subtitle1" className="text-start text-light">
                                        <img alt="quote" src="./assets/images/quote.png" height="15" />&nbsp; Daniel Negreanu
                                    </Typography>
                                </Grid>
                                    <Typography variant="caption" className="text-muted text-start text-light">
                                        $49,900,000 in earnings 🏆
                                    </Typography>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item md={6} xs={12} px={1} pt={{xs:2, sm:0}}  justifyContent={'center'}>
                <div style={{
                    backgroundImage: `url('./assets/images/img_Bill_Perkins.png')`,
                    backgroundPosition: 'center',
                    position:'relative',
                    backgroundSize: 'cover',
                    borderRadius: 16,
                    textAlign:'center',
                    padding:0,
                    width: '100%',
                    height: '600px',
                }}>
                    <div
                        className="text-center hyb-glass-card p-2 hyb-glass-card-bottom"

                    >
                        <CardContent>
                            <Typography variant="body1" className="text-light hyb-light-text text-left" textAlign={'left'}>
                                Hybrid Poker really accelerates learning and understanding, especially valuable for those of us with full schedules and lives.
                            </Typography>
                        </CardContent>
                        <div style={{ borderTop: '0.75px solid white' }}>
                            <Grid container justifyContent="start" direction={'column'} alignItems="start" p={2}>
                                <Grid item>
                                    <Typography variant="subtitle1" className="text-start text-light">
                                        <img alt="quote" src="./assets/images/quote.png" height="15" />&nbsp; Bill Perkins
                                    </Typography>
                                </Grid>
                                    <Typography variant="caption" className="text-muted text-start text-light">
                                        $5,500,000 in earnings 🏆
                                    </Typography>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default Testimonials;
