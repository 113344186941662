import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {IconButton} from "@mui/material";
import {FiX} from "react-icons/fi";
import Vimeo, {TimeUpdateEvent} from "@u-wave/react-vimeo";
import {useCallback, useMemo} from "react";
import {throttle} from 'lodash';
import {VimeoVideoObject} from "../../api/fetchVideo";
import useUpdateVideoProgress from "../../api/upsertVideoProgress";


export default function VideoPlayerModal({selectedVideo, onClose, initialProgress, token}: {
    token?: string,
    initialProgress: number,
    selectedVideo?: VimeoVideoObject,
    onClose?: () => void,
}) {
    const start = useMemo(() => {
        if (selectedVideo?.duration) {
            return selectedVideo.duration * initialProgress
        }
        return 0
    }, [initialProgress, selectedVideo?.duration])

    const { mutate: updateVideoProgress } = useUpdateVideoProgress();

    const updateProgress = useCallback((event: TimeUpdateEvent) => {
        let currentProgress = event.percent;
        if (currentProgress <= initialProgress) {
            return;
        }
        if(initialProgress === 1){
            return;
        }

        if (selectedVideo?.id && token && currentProgress) {
            if(currentProgress>0.96){
                currentProgress = 1
            }
            updateVideoProgress({videoId:selectedVideo.id, data:{
                    percent: currentProgress,
                    duration:event.duration,
                    seconds:event.seconds
                } });
        }
    }, [updateVideoProgress, selectedVideo?.id, token, initialProgress]);

    const throttledUpdateProgress = useMemo(() => throttle(updateProgress, 10000, { leading: true, trailing: false }), [updateProgress]);

    return (
        <Modal
            onClose={onClose}
            open={!!selectedVideo?.id}
            style={{background: 'black'}}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw'
            }}>
                <IconButton
                    sx={{background: '#000000ab', position: 'absolute', right: '8px', top: '8px', zIndex:2}}
                    onClick={onClose}>
                    <FiX color={'white'} fontSize={'28px'}/>
                </IconButton>
                <Box sx={{
                ...((selectedVideo?.width??0) >( selectedVideo?.height??0)?{
                    width: '880px',
                }:{
                    height: '600px',
                    width:(600*(selectedVideo?.ratio??1))+'px'
                }),
                    position: 'relative',
                }}>
                    <Vimeo
                        video={selectedVideo?.id??''}
                        // video={'115783408' ?? selectedVideo?.uri}
                        // autoplay
                        pip
                        responsive
                        showTitle
                        controls
                        start={start}
                        onTimeUpdate={throttledUpdateProgress}
                    />

                </Box>
            </Box>

        </Modal>
    );
}
