import {useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import {useSnackbar} from "notistack";

export default function useDiscordConnect() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
        const error = searchParams.get("discord_error");
        if (error) {
          enqueueSnackbar("Unable to connect!", {
            variant: "error",
          });
        }
      searchParams.delete("discord_error");
      setSearchParams(searchParams);
    })();
  }, [searchParams, setSearchParams, enqueueSnackbar]);

}
