import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import * as React from "react";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAuthenticator} from "@aws-amplify/ui-react";
import { FiUser, FiLogOut, FiDollarSign } from "react-icons/fi";
import { API } from "aws-amplify";

export type ProfileMenuProps = PopupState & {
  hasSubscription: boolean;
};

const ProfileMenu: FC<ProfileMenuProps> = ({
  hasSubscription,
  ...popupState
}) => {
  const queryClient = useQueryClient();
  const { signOut, user } = useAuthenticator();

  // @ts-ignore
  const token = user?.signInUserSession?.idToken?.jwtToken;

  const handleLogout = async () => {
    popupState.close();
    await signOut();
    queryClient.clear();
  };

  const manage = async () => {
    const requestData = {
      headers: {
        Authorization: token,
      },
    };
    popupState.close();
    const data = await API.put("hypLambdaPayment", "/checkout", requestData);
    if (data.url) window.location.href = data.url;
  };

  const { mutateAsync, isLoading } = useMutation("ManageSubscription", manage);

  return (
    <Menu {...bindMenu(popupState)}>
      <MenuItem disabled data-cy="menu-item-profile" key="profile" dense>
        <ListItemIcon>
          <FiUser fontSize="small" />
        </ListItemIcon>
        <ListItemText>{user?.attributes?.name}</ListItemText>
      </MenuItem>
      {hasSubscription && (
        <MenuItem
          data-cy="menu-item-logout"
          onClick={() => mutateAsync()}
          dense
        >
          <ListItemIcon>
            <FiDollarSign fontSize="small" />
          </ListItemIcon>
          <ListItemText>Manage Subscription</ListItemText>
        </MenuItem>
      )}
      <MenuItem data-cy="menu-item-logout" onClick={handleLogout} dense>
        <ListItemIcon>
          <FiLogOut fontSize="small" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
