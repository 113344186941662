import { useAuthenticator, Button, Flex, Text } from "@aws-amplify/ui-react";
import { FC, useEffect, useState } from "react";
import { useMutation } from "react-query";
import jsonp from "jsonp";
import queryString from "query-string";
import {
  Box,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { API, Auth } from "aws-amplify";

type BuyPlanProps = {
  flow: string | null;
  resetFlow: () => void;
};
const BuyPlan: FC<BuyPlanProps> = ({ flow, resetFlow }) => {
  const { user } = useAuthenticator();
  // @ts-ignore
  const token = user?.signInUserSession?.idToken?.jwtToken;
  const proPlan = JSON.parse(process.env.REACT_APP_PRO_PLAN ?? "{}");
  const preflopPlan = JSON.parse(process.env.REACT_APP_PRE_FLOP_PLAN ?? "{}");
  const isPreFlop = sessionStorage.getItem("isPreflop");
  const [stripeId, setStripeId] = useState("");

  const buy = async (stripeId: string) => {
    // handleClickOpen();
    const requestData = {
      headers: {
        Authorization: token,
      },
    };
    const data = await API.get(
      "hypLambdaPayment",
      "/checkout?planId=" + stripeId,
      requestData
    );
    console.log({ data });
    if (data.url) window.location.href = data.url;
    if (data.action) {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: any, session: any) => {
          window.location.reload();
        }
      );
    }
  };

  const { mutateAsync, isLoading } = useMutation("ManageSubscription", buy);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    const nameArr = user?.attributes?.name.split(" ");
    const formData = {
      EMAIL: user?.attributes?.email,
      FNAME: nameArr?.[0],
      LNAME: nameArr?.[1],
      tags: 8229075,
    };
    jsonp(
      `https://hybridpoker.us7.list-manage.com/subscribe/post?u=ca0f48ce559b62a882f40c471&id=430cbea72b&f_id=0018c4e4f0&${queryString.stringify(
        formData
      )}`,
      { param: "c" },
      (err, data) => {
        console.log(err);
      }
    );
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (flow && !isLoading) {
      const sid =
        flow === "buy-yearly"
          ? proPlan.annually.stripeId
          : proPlan.monthly.stripeId;
      setStripeId(sid);
      mutateAsync(sid);
      resetFlow();
    }
  }, []);

  return (
    <Container sx={{paddingLeft:'8px',paddingRight:'8px'}}>
      <Grid container mt={{xs:1,sm:4}} mb={{xs:1,sm:4}} >
        <Grid item xs={12}>
          <Flex
            direction="column"
            alignItems="center"
            className="pricing-header mx-auto text-center"
            paddingTop={'8px'}
            paddingBottom={'16px'}
            style={{gap: 'var(--amplify-space-small);'}}
          >
            <Typography
              m={0}
              variant={"h4"}
              className="hyb-sub-heading text-light"
            >
              Choose a <span className="text-gradient">plan</span> to begin
            </Typography>
            <Text color={"white"} textAlign={"center"}>
              Our plan includes unlimited access to the platform and unlocks all
              the benefits
            </Text>
          </Flex>
        </Grid>
      </Grid>

      <Flex justifyContent={"center"} wrap={"wrap"}>
        {isPreFlop && (
            <Box
                className="
                  card
                  shadow-sm
                  hyb-price-card
                "
            >
              <div
                  className="card-header py-3"
                  style={{
                    borderBottom: " 0.5px solid #ffffff63",
                  }}
              >
                <Typography
                    variant={"h6"}
                    my={2}
                    px={2}
                    className="my-0 fw-normal text-light"
                >
                  PREFLOP MONTHLY
                </Typography>

              </div>
              <Box px={2} className="card-body">
                <Box my={3} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                  <Typography
                      mt={1}
                      className="text-light text-secondary"
                  >
                    Preflop Access!
                  </Typography>
                  <Box >
                    <Typography
                        mt={1}
                        className="text-light text-secondary"
                        color={'white'}
                    >
                      &nbsp;
                    </Typography>
                  </Box>
                  <Typography
                      mt={3}
                      mb={1}
                      variant={"h4"}
                      className="card-title pricing-card-title text-light txt-green"
                  >
                    $9.99
                    <Box
                        mx={1}
                        component={"span"}
                        fontSize={"1rem"}
                        className=" text-secondary txt-green"
                        sx={{verticalAlign:'super'}}
                    >
                      /mo
                    </Box>
                  </Typography>
                  <Text  color={"white"} className="text-light">
                      <span className="text-secondary text-light">
                        Billed monthly
                      </span>
                  </Text>
                </Box>

                <Button
                    loadingText="Subscribe Now"
                    variation={"link"}
                    isLoading={
                        isLoading && stripeId === preflopPlan.monthly.stripeId
                    }
                    disabled={isLoading}
                    onClick={() => {
                      setStripeId(preflopPlan.monthly.stripeId);
                      mutateAsync(preflopPlan.monthly.stripeId);
                    }}
                    className="hyb-price-fill-cta"
                >
                  Get Started
                </Button>
              </Box>
            </Box>
        )}
        <Box
            className="
                  card
                  shadow-sm
                  hyb-price-card
                "
            p={0}
            sx={{position: "relative"}}
        >
          <div
              className="card-header py-3"
              style={{
                borderBottom: " 0.5px solid #ffffff63",
              }}
          >
            <Typography
                variant={"h6"}
                my={2}
                px={2}
                className="my-0 fw-normal text-light"
            >
              ANNUAL
            </Typography>
            <img
                alt="best-value"
                src="./../assets/images/best-value.png"
                style={{
                  position: "absolute",
                  right: 0,
                  height: "40px",
                  top: "12px",
                }}
            />
          </div>
          <Box px={2} className="card-body">
            <Box my={3} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
              <Typography
                  mt={1}
                  className="text-light text-secondary"
              >
                Full Exclusive Access!
              </Typography>
              <Box sx={{textDecoration:'line-through', color:'red', textDecorationThickness: '3px'}}>
                <Typography
                    mt={1}
                    className="text-light text-secondary"
                    color={'white'}
                >
                  Normally $79/mo
                </Typography>
              </Box>
                <Typography
                  mt={3}
                  mb={1}
                  variant={"h4"}
                  className="card-title pricing-card-title text-light txt-green"
              >
                $69
                <Box
                    mx={1}
                    component={"span"}
                    fontSize={"1rem"}
                    className=" text-secondary txt-green"
                    sx={{verticalAlign:'super'}}
                >
                  /mo
                </Box>
                <Box
                    component={"span"}
                    fontSize={"1rem"}
                    className=" hyb-save-chip"
                >
                  Save ~13%
                </Box>
              </Typography>
              <Text  color={"white"} className="text-light">
                <span className="text-secondary text-light">
                  Billed annually (save $120/year)
                </span>
              </Text>
            </Box>

            <Button
                loadingText="Subscribe Now"
                variation={"link"}
                isLoading={isLoading && stripeId === proPlan.annually.stripeId}
                disabled={isLoading}
                onClick={() => {
                  setStripeId(proPlan.annually.stripeId);
                  mutateAsync(proPlan.annually.stripeId);
                }}
                className="hyb-price-fill-cta"
            >
              Get Started
            </Button>
          </Box>
          <Box  px={2} my={3}  sx={{borderTop: " 0.5px solid #ffffff63",}}>
          <Typography pt={3} variant={'body1'} >
            <Box component={"span"} className='txt-green'>✓ Hybrid Poker App - Full</Box> <br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Preflop<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Postflop<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> AI Guided Coaching<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Game Tree Access<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Natural Play Mode<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Range Play Mode<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Full Access to all of our Courses<br/>
            <Box  component={"span"} className='txt-green'>✓ Hand History Lounge - Discord</Box> <br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Ability to READ<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Ability to POST<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Get Answers from the Pros!<br/>
            <Box ml={2} component={"span"} className='txt-green'>✓</Box> Full Discord Access<br/>
          </Typography>
          </Box>
          <Box  px={2} my={3}>
            <Button
                loadingText="Subscribe Now"
                variation={"link"}
                isLoading={isLoading && stripeId === proPlan.annually.stripeId}
                disabled={isLoading}
                onClick={() => {
                  setStripeId(proPlan.annually.stripeId);
                  mutateAsync(proPlan.annually.stripeId);
                }}
                className="hyb-price-fill-cta"
            >
              Get Started
            </Button>
          </Box>
          <Text color={"white"} className="text-muted text-light hyb-price-tax">
            * VAT & local taxes may apply
          </Text>
        </Box>

        <Box
            className="
                  card
                  shadow-sm
                  hyb-price-card
                "
            p={0}
            sx={{ position: "relative" }}
        >
          <div
              className="card-header py-3"
              style={{
                borderBottom: " 0.5px solid #ffffff63",
              }}
          >
            <Typography
                variant={"h6"}
                my={2}
                px={2}
                className="my-0 fw-normal text-light"
            >
              MONTHLY
            </Typography>
          </div>
          <Box px={2} className="card-body">
            <Box my={3} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
              <Typography
                  mt={1}
                  className="text-light text-secondary"
              >
                Full Exclusive Access!
              </Typography>
              <Box>
                <Typography
                    mt={1}
                    className="text-light text-secondary"
                    color={'white'}
                >
                &nbsp;
                </Typography>
              </Box>
              <Typography
                  mt={3}
                  mb={1}
                  variant={"h4"}
                  className="card-title pricing-card-title text-light"
              >
                $79
                <Box
                    mx={1}
                    component={"span"}
                    fontSize={"1rem"}
                    className=" text-secondary"
                    sx={{verticalAlign:'super'}}
                >
                  /mo
                </Box>

              </Typography>
              <Text  color={"white"} className="text-light">
                <span className="text-secondary text-light">
                  Billed monthly
                </span>
              </Text>
            </Box>

            <Button
                loadingText="Subscribe Now"
                variation={"link"}
                isLoading={isLoading && stripeId === proPlan.monthly.stripeId}
                disabled={isLoading}
                onClick={() => {
                  setStripeId(proPlan.monthly.stripeId);
                  mutateAsync(proPlan.monthly.stripeId);
                }}
                className="hyb-price-fill-cta"
            >
              Get Started
            </Button>
          </Box>
          <Box  px={2} my={3}  sx={{borderTop: " 0.5px solid #ffffff63",}}>
            <Typography pt={3} variant={'body1'} >
              <Box component={"span"} className='txt-green'>✓ Hybrid Poker App - Full</Box> <br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Preflop<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Postflop<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> AI Guided Coaching<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Game Tree Access<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Natural Play Mode<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Range Play Mode<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Full Access to all of our Courses<br/>
              <Box  component={"span"} className='txt-green'>✓ Hand History Lounge - Discord</Box> <br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Ability to READ<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Ability to POST<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Get Answers from the Pros!<br/>
              <Box ml={2} component={"span"} className='txt-green'>✓</Box> Full Discord Access<br/>
            </Typography>
          </Box>
          <Box  px={2} my={3}>
            <Button
                loadingText="Subscribe Now"
                variation={"link"}
                isLoading={isLoading && stripeId === proPlan.monthly.stripeId}
                disabled={isLoading}
                onClick={() => {
                  setStripeId(proPlan.monthly.stripeId);
                  mutateAsync(proPlan.monthly.stripeId);
                }}
                className="hyb-price-fill-cta"
            >
              Get Started
            </Button>
          </Box>
          <Text color={"white"} className="text-muted text-light hyb-price-tax">
            * VAT & local taxes may apply
          </Text>
        </Box>
      </Flex>

      <Dialog
        className="hyb-modal"
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle color={"white"}>
          <Typography mb={2} variant={"h5"} className="text-light">
            Thank you for your interest!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Text color={"white"}>
              We're temporarily disabling new subscriptions while we upgrade our
              servers to keep up with the overwhelming demand. We'll email you
              once we're back online.
            </Text>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variation={"link"}
            className="hyb-close-cta"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BuyPlan;
