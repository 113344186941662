import { useQuery } from 'react-query';

type VideoProgressData = {
    videoId: string;
    videoProgress: ProgressMap;
};

type ProgressMap = {
    duration: number;
    percent: number
    seconds: number
};

const API_URL: string = process.env.REACT_PROGRESS_APP_API_URL || 'https://awsapi.dev.hybridpoker.com';

const getVideoProgress = async (token: string): Promise<ProgressMap> => {
    const response = await fetch(`${API_URL}/api/gse/getVideoProgress`, {
        method: 'GET',
        headers: {
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to fetch video progress');
    }

    const data: VideoProgressData[] = await response.json();
    // Transform data into the desired format
    const progressMap: ProgressMap = data.reduce((acc:any, item) => {
        const progressData = item.videoProgress
        acc[item.videoId as string] = +(progressData.percent??0);
        return acc;
    }, {});

    return progressMap ??{};
};

const useVideoProgress = (token: string | null): any => {
    return useQuery('videoProgress', () => getVideoProgress(token || ''), {
        enabled: !!token, // Only fetch data if token is available
    });
};

export default useVideoProgress;
