import { useMutation, useQueryClient, MutationFunction } from 'react-query';

const API_URL: string = process.env.REACT_PROGRESS_APP_API_URL || 'https://awsapi.dev.hybridpoker.com';

type VideoProgress = {
    duration: number;
    percent: number
    seconds: number
};

type UpsertVideoProgressData = {
    data: VideoProgress;
    videoId: string;
};

const upsertVideoProgress: MutationFunction<null, UpsertVideoProgressData> = async ({ data, videoId }) => {
    const response = await fetch(`${API_URL}/api/gse/upsertVideoProgress?id=${videoId}`, {
        method: 'POST',
        headers: {
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error('Failed to update video progress');
    }

    return null
};

const useUpdateVideoProgress = () => {
    const queryClient = useQueryClient();

    return useMutation(upsertVideoProgress, {
        onSuccess: (_, variables) => {
            queryClient.setQueryData<Record<string, number>>('videoProgress', (prevData) => {

                if(prevData){
                    return {
                        ...prevData,
                        [variables.videoId]: variables.data.percent
                    }
                }
                return {
                    [variables.videoId]: variables.data.percent
                }
            });
        }
    });
};

export default useUpdateVideoProgress;
