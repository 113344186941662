import { UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';

export interface VimeoVideoObject {
    id?: string;
    uri: string;
    name?: string;
    duration?: number;
    height?: number;
    width?: number;
    ratio?: number;
    description?: string;
    link?: string;
    tags?: string[];
    pictures?: any[];
}

interface VimeoResponse {
    total: number;
    page: number;
    per_page: number;
    paging: {
        next: string | null;
        previous: string | null;
        first: string;
        last: string;
    };
    data: VimeoVideoObject[];
}

interface UseVimeoVideosProps {
    perPage?: number;
    query?: string;
    filterTags?: string[];
    excludedTags?: string[];
}

const fetchVimeoVideos = async (url: string): Promise<VimeoResponse> => {
    const response = await fetch(url, {
        headers: {
            Authorization: 'Bearer '+process.env.REACT_APP_VIMEO_TOKEN,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Vimeo videos');
    }

    return response.json();
};

const useVimeoVideos = ({ query, filterTags, perPage,  excludedTags }: UseVimeoVideosProps): UseInfiniteQueryResult<VimeoResponse> => {
    const fetchVideos = async ({ pageParam }:{ pageParam?:string }) => {
       if(pageParam){
           const url = `https://api.vimeo.com${pageParam}`;
           return fetchVimeoVideos(url);
       }else{
           const queryParams = new URLSearchParams({
               direction: 'desc',
               sort: 'date',
               fields: 'id,uri,name,duration,description,pictures,height,width',
               per_page: perPage?perPage+'':'25',
               page:'1',
               ...(excludedTags?.length ? {filter_tag_exclude: excludedTags.toString()}:{})
           });
           if (query) {
               queryParams.append('query', query);
           }
           let url = `https://api.vimeo.com/me/videos?${queryParams.toString()}`;
           if (filterTags && filterTags.length > 0) {
               const tagsString =filterTags.sort((a, b) => (a.includes('=') && !b.includes('=')) ? 1 : ((!a.includes('=') && b.includes('=')) ? -1 : 0)).join(',');
               url += `&filter_tag=${tagsString}`;
           }

           return fetchVimeoVideos(url);

       }

    };

    return useInfiniteQuery(['vimeoVideos', query, filterTags, excludedTags, perPage], fetchVideos, {
        getNextPageParam: (lastPage) => lastPage.paging.next,
    });
};

export default useVimeoVideos;
