import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {IconButton} from "@mui/material";
import {FiImage} from "react-icons/fi";
import {Image} from "@aws-amplify/ui-react";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '800px',
    width:'100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BackgroundSelector() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const setBackground = (img:string|null) => {
        var appBgElement = document.getElementById("App-BG");
        if(appBgElement) {
            if (img) {
                appBgElement.style.backgroundImage = `url(${img})`;
            }
        }
        setOpen(false);
    }

    return (
        <Box sx={{display:{xs:'none',sm:'block'}}}>
            <IconButton sx={{background: '#000000ab', position: 'absolute', right: '8px', bottom: '8px'}}
                        onClick={() => handleOpen()}>
                <FiImage color={'white'} fontSize={'28px'}/>
            </IconButton>
            <Modal
                open={open}
                onClose={()=>setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography mb={1} id="modal-modal-title" variant="h6" component="h2">
                        Choose a background
                    </Typography>
                    <Box justifyContent={'center'} display={'flex'} gap={'14px'} flexDirection={'row'} flexWrap={'wrap'}>
                        <Image width={'300px'} height={'180px'} alt={'img-1'} src={"/assets/images/bg-3.jpg"}
                               style={{borderRadius: '12px', overflow: 'hidden', cursor:'pointer'}}
                               onClick={() => setBackground("/assets/images/bg-3.jpg")}/>
                        <Image width={'300px'} height={'180px'} alt={'img-2'} src={"/assets/images/bg-2.jpg"}
                               style={{borderRadius: '12px', overflow: 'hidden', cursor:'pointer'}}
                               onClick={() => setBackground('/assets/images/bg-2.jpg')}/>
                        <Image width={'300px'} height={'180px'} alt={'img-3'} src={"/assets/images/bg-1.jpg"}
                               style={{borderRadius: '12px', overflow: 'hidden', cursor:'pointer'}}
                               onClick={() => setBackground("/assets/images/bg-1.jpg")}/>
                        <Box width={'300px'} height={'180px'}
                               style={{borderRadius: '12px', overflow: 'hidden', cursor:'pointer', background:'#151515'}}
                               onClick={() => setBackground("/assets/images/bg-4.jpg")}/>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}
