import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Flex,
    Image, Link, Loader,
    Text,
    useAuthenticator,
} from "@aws-amplify/ui-react";
import {API, Auth} from "aws-amplify";
import {Box, Typography} from "@mui/material";
import {useMutation} from "react-query";
import useDiscordConnect from "./hooks/useDiscordConnect";
import {useNavigate} from "react-router-dom";

const Discord = () => {
    useDiscordConnect();
    const {user} = useAuthenticator();
    const [isDiscordLinked, setIsDiscordLinked] = useState(false);
    const [isChecking, setIsChecking] = useState(true);
    // @ts-ignore
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const join = async () => {
        const requestData = {
            headers: {
                Authorization: token,
            },
        };
        const data = await API.get(
            "hypLambdaPayment",
            `/discord`,
            requestData
        );
        if (data.url) window.location.href = data.url;
    };

    const {mutateAsync, isLoading} = useMutation("joinDiscord", join);
    const checkDiscord = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });
            if (user?.attributes["custom:discordUserName"]) {
                setIsDiscordLinked(true);
            } else {
                // if JWT is not updated
                const info = await Auth.currentUserInfo();
                if (info.attributes["custom:discordUserName"]) {
                    setIsDiscordLinked(true);
                }
            }
            setIsChecking(false)
        } catch (e) {
            setIsChecking(false)
            console.log(e);
            setIsDiscordLinked(false);
        }
    };

    useEffect(() => {
        checkDiscord();
    }, []);

    const navigate = useNavigate();

    if (isChecking) {
        return <Loader
            style={{
                position: "absolute",
                top: "25em",
                left: "50%",
                zIndex: 0,
                height: "60px",
            }}
            size="large"
        />
    }
    return (
        <Card
            display={"flex"}
            height="100vh"
            columnStart="1"
            columnEnd="-1"
            style={{
                zIndex: 2,
                flexDirection: "column",
                background: "transparent",
                overflowX: "hidden",
            }}
        >
            <Flex
                justifyContent="space-between"
                height="50px"
                placeholder={undefined}
            >
                <a href={
                    process.env.REACT_APP_WEBSITE_URL ||
                    "https://www.hybridpoker.com"
                }>
                    <Image
                        width={"200px"}
                        alt="logo"
                        src="./assets/images/Hybrid-Logo-Hor-2x.png"
                        placeholder={undefined}
                    />
                </a>
            </Flex>
            <Flex
                marginTop={"5%"}
                alignItems="center"
                grow={1}
                direction={"column"}
                placeholder={undefined}
            >
                <Flex placeholder={undefined}>
                    <Flex justifyContent={"center"} wrap={"wrap"}>
                        <Box
                            className="card shadow-sm hyb-manage-card"
                            p={2}
                            mt={5}
                            sx={{height: "380px"}} // Adjust the height here
                        >
                            <div
                                className="card-header py-3"
                                style={{
                                    borderBottom: "0.5px solid white",
                                }}>
                                <Text marginBottom={"16px"} marginTop={"16px"} color={"white"} placeholder={undefined}>
                                    {isDiscordLinked ? "Hand History Lounge" : "Hand History Lounge"}
                                </Text>
                            </div>
                            <Box mt={5} className="card-body">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Image src="./assets/images/discord-icon.png" width="100px" height="100px" alt="Discord"
                                           placeholder={undefined} opacity={0.75} marginBottom={'16px'} />
                                </div>
                                {isDiscordLinked ? <Button
                                    variation={"link"}
                                    onClick={() => window.location.href = process.env.REACT_APP_DISCORD_URL ||
                                        "https://discord.com/channels/1208073762260647977/1208073762260647980"}
                                    className="hyb-price-cta">
                                    Join Subscriber Discord
                                </Button> : <Button
                                    loadingText="Unlock Subscriber Discord"
                                    variation={"link"}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    onClick={() => mutateAsync()}
                                    className="hyb-price-cta">
                                    Unlock Subscriber Discord
                                </Button>}
                                <Box width={'100%'} textAlign={'center'}>
                                    <Link
                                        fontSize={14}
                                        color={'white'}
                                        onClick={() => navigate('/')}
                                    >
                                        Go back
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
};

export default Discord;
