import React, {useEffect, useState} from "react";
import {
    Button,
    Image, Loader,
    Text,
    useAuthenticator,
} from "@aws-amplify/ui-react";
import {API, Auth} from "aws-amplify";
import {Box} from "@mui/material";
import {useMutation} from "react-query";
import {useNavigate} from "react-router-dom";

const DicordCard = () => {
    const {user} = useAuthenticator();
    const [isDiscordLinked, setIsDiscordLinked] = useState(false);
    const [isChecking, setIsChecking] = useState(true);
    // @ts-ignore
    const token = user?.signInUserSession?.idToken?.jwtToken;
    const join = async () => {
        const requestData = {
            headers: {
                Authorization: token,
            },
        };
        const data = await API.get(
            "hypLambdaPayment",
            `/discord`,
            requestData
        );
        if (data.url) window.location.href = data.url;
    };

    const {mutateAsync, isLoading} = useMutation("joinDiscord", join);
    const checkDiscord = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });
            if (user?.attributes["custom:discordUserName"]) {
                setIsDiscordLinked(true);
            } else {
                // if JWT is not updated
                const info = await Auth.currentUserInfo();
                if (info.attributes["custom:discordUserName"]) {
                    setIsDiscordLinked(true);
                }
            }
            setIsChecking(false)
        } catch (e) {
            setIsChecking(false)
            console.log(e);
            setIsDiscordLinked(false);
        }
    };

    useEffect(() => {
        checkDiscord();
    }, []);

    const navigate = useNavigate();

    if (isChecking) {
        return<>
            <Box
                className="card shadow-sm hyb-manage-card"
                p={2}
                mt={5}
                sx={{height: "336px"}}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >

            <Loader
                style={{
                    zIndex: 0,
                    height: "60px",
                }}
            />
            </Box>
        </>
    }


    return (
        <Box
            className="card shadow-sm hyb-manage-card"
            p={2}
            mt={5}
            sx={{height: "336px"}} // Adjust the height here
        >
            <div
                className="card-header py-3"
                style={{
                    borderBottom: "0.5px solid white",
                }}>
                <Text marginBottom={"16px"} marginTop={"16px"} color={"white"} placeholder={undefined}>
                    {isDiscordLinked ? "Hand History Lounge" : "Hand History Lounge"}
                </Text>
            </div>
            <Box mt={5} className="card-body">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Image src="./assets/images/discord-icon.png" width="100px" height="100px" alt="Discord"
                           placeholder={undefined} opacity={0.75} />
                </div>
                {isDiscordLinked ? <Button
                    variation={"link"}
                    onClick={() => window.location.href = process.env.REACT_APP_DISCORD_URL ||
                        "https://discord.com/channels/1208073762260647977/1208073762260647980"}
                    className="hyb-price-cta">
                    Join Subscriber Discord
                </Button> : <Button
                    loadingText="Unlock Subscriber Discord"
                    variation={"link"}
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={() => mutateAsync()}
                    className="hyb-price-cta">
                    Unlock Subscriber Discord
                </Button>}
            </Box>
        </Box>
    );
};

export default DicordCard;
