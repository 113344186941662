import { useQuery } from 'react-query';

interface VimeoTag {
    uri: string;
    name: string;
    tag: string;
    canonical: string;
    resource_key: string;
}

interface VimeoResponse {
    total: number;
    page: number;
    per_page: number;
    paging: {
        next: string | null;
        previous: string | null;
        first: string;
        last: string;
    };
    data: {
        tags: VimeoTag[];
    }[];
}

const fetchVimeoTags = async (url: string): Promise<VimeoResponse> => {
    const response = await fetch(url, {
        headers: {
            Authorization: 'Bearer '+process.env.REACT_APP_VIMEO_TOKEN,
        }
    });
    if (!response.ok) {
        throw new Error('Failed to fetch tags');
    }
    return response.json();
};

const useVimeoTags = () => {
    const fetchTags = async () => {
        const tags = []
        const url = `https://api.vimeo.com/me/videos?direction=desc&sort=date&fields=tags&per_page=100&page=1`;
        const data = await fetchVimeoTags(url);
        tags.push(...data.data.flatMap(page => page.tags.flatMap(entry => entry.canonical)))
        if(data.total>100){
            const url = `https://api.vimeo.com/me/videos?direction=desc&sort=date&fields=tags&per_page=100&page=2`;
            const data = await fetchVimeoTags(url);
            tags.push(...data.data.flatMap(page => page.tags.flatMap(entry => entry.canonical)))
        }
        //return Array.from(new Set(tags)).filter(tag=>!tag.startsWith('noshow')).sort();
        var returnArray = Array.from(new Set(tags)).filter(tag=>!tag.startsWith('noshow')).sort();

        // move the value series_hand_breakdowns to the first item in the array
        const index = returnArray.indexOf('series_hand_breakdowns');
        if (index > -1) {
            returnArray.splice(index, 1);
            returnArray.unshift('series_hand_breakdowns');
        }

        return returnArray;
    };

    return useQuery('vimeoTags', fetchTags, {});
};

export default useVimeoTags;
